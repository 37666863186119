@import '../main/theme';
@import '../main/jigsaw';

:root {
  /**
   * Classic Header -> src/components/headers/ClassicHeader/index.tsx
   */
  --classic-header-logo-height: 52.5px;
  --classic-header-heading-font-weight: 300;

  @include mq($from: desktop) {
    --classic-header-height: 130px;
    --classic-header-logo-height: 105px;
  }

  /**
   * Ripple
   */
  --ripple-color: #7014cb;

  /**
   * Hub Home Hero Banner (Brand Hub only)
   */
  .brand-hub .hub-home-hero-module {
    --hub-hero-module-value-proposition-font-size: 24px;

    @include mq($from: tablet) {
      --hub-hero-module-value-proposition-font-size: 32px;
    }

    @include mq($from: desktop) {
      --hub-hero-module-value-proposition-font-size: 48px;
    }

    @include mq($from: wide) {
      --hub-hero-module-value-proposition-font-size: 65px;
    }

    --hub-hero-module-value-proposition-font-weight: 300;
    --hub-hero-module-value-proposition-line-height: 140%;
  }

  /**
  * Hub Column Text Module
  */
  --hub-column-text-module-brand-dark-color: #fff;
  --hub-column-text-module-brand-dark-background-color: #33004e;
  --hub-column-text-module-brand-light-color: #fff;
  --hub-column-text-module-brand-light-background-color: #7014cb;
  --hub-column-text-module-icon-width: 108px;
  --hub-column-text-module-icon-height: 108px;

  /**
   * Classic Footer -> src/components/footers/ClassicFooter/index.tsx
   */
  --classic-footer-logo-max-height: 104px;
}

.brand-hub {
  .hub-home-hero-module {
    @import '../main/ripple';

    @include mq($from: tablet) {
      min-height: 290px;
    }

    @include mq($from: desktop) {
      min-height: 490px;
    }

    @include mq($from: wide) {
      min-height: 690px;
    }

    .hub-module-ripples {
      @include mq($until: tablet) {
        display: none;
      }

      @include mq($from: 768px) {
        top: 120px;
        right: 225px;
      }

      @include mq($from: 1024px) {
        top: 110px;
        right: 298px;
      }

      // iPad Pro Landscape
      @include mq($from: 1366px) {
        top: 95px;
        right: 398px;
      }

      // MacBook Pro 13" - 1400px / 1440px
      @include mq($from: 1400px) {
        top: 95px;
        right: 420px;
      }

      // MacBook Pro 14"
      @include mq($from: 1512px) {
        top: 95px;
        right: 440px;
      }

      // MacBook Pro 15"
      @include mq($from: 1680px) {
        top: 85px;
        right: 490px;
      }

      // MacBook Pro 16"
      @include mq($from: 1728px) {
        top: 85px;
        right: 503px;
      }

      // FHD
      @include mq($from: 1920px) {
        top: 78px;
        right: 560px;
      }

      // QHD
      @include mq($from: 2560px) {
        top: 50px;
        right: 745px;
      }

      // 4K
      @include mq($from: 3840px) {
        top: 5px;
        right: 1117px;
      }
    }
  }
}
