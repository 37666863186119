$count: 30;
$animation-duration: 12s;
$ripple-animation-delay: 0.4s;

$ripple-default-width: 1px;
$ripple-gutter: 30px;
$ripple-color: rgb(0, 203, 203);
$animation-start-size: $ripple-gutter;
$animation-end-size: $count * $ripple-gutter;
$designer-mode: false;

$jigsaw-fix: 75px;

@keyframes hero-ripple {
  0% {
    top: calc(50% - $animation-start-size / 2 - $jigsaw-fix);
    left: calc(50% - $animation-start-size / 2);
    width: $animation-start-size;
    height: $animation-start-size;
    border-color: $ripple-color;
  }
  90% {
    border-color: $ripple-color;
  }
  98% {
    border-color: transparent;
  }
  100% {
    top: calc(50% - $animation-end-size / 2 - $jigsaw-fix);
    left: calc(50% - $animation-end-size / 2);
    width: $animation-end-size;
    height: $animation-end-size;
    border-color: transparent;
  }
}

.hub-module-ripples {
  position: absolute;
  top: 85px;
  right: 503px;
  bottom: 0;
}

@for $index from 1 through $count {
  .hub-module-ripple-#{$index} {
    position: absolute;
    border: $ripple-default-width solid transparent;
    border-radius: 50%;

    $delay: $ripple-animation-delay * $index;
    animation: hero-ripple $animation-duration $delay linear infinite;

    @if $designer-mode {
      top: calc(50% - $index * $ripple-gutter / 2 - $jigsaw-fix);
      left: calc(50% - $index * $ripple-gutter / 2);
      width: $index * $ripple-gutter;
      height: $index * $ripple-gutter;
      border: $ripple-default-width solid $ripple-color;
      animation: none;
    }

    @each $ten in (0 10 20) {
      @if $index == 1 + $ten {
        opacity: 0.4;
      }

      @if $index == 2 + $ten {
        opacity: 0.5;
      }

      @if $index == 3 + $ten {
        opacity: 0.6;
        border-width: $ripple-default-width + 1px;
      }

      @if $index == 4 + $ten {
        opacity: 0.8;
        border-width: $ripple-default-width + 1px;
      }

      @if $index == 5 + $ten {
        opacity: 1;
        border-width: $ripple-default-width + 2px;
      }

      @if $index == 6 + $ten {
        opacity: 0.8;
        border-width: $ripple-default-width + 1px;
      }

      @if $index == 7 + $ten {
        opacity: 0.6;
      }

      @if $index == 8 + $ten {
        opacity: 0.5;
      }

      @if $index == 9 + $ten {
        opacity: 0.4;
      }

      @if $index == 10 + $ten {
        opacity: 0.3;
      }
    }
  }
}
